<template>
  <div class="mb-4">
      <CRow class="">
        <CCol lg="3">
          <CInput v-model="booking_no" placeholder="Booking No."/>
        </CCol>
        
        <CCol lg="3">
          <v-select 
            label="customer_name" 
            :options="customerList.data"
            :reduce="item => item.id"
            v-model="customer_id"
            placeholder="Customer"
          > 
          </v-select> 
        </CCol> 
        <CCol lg="3">
          <v-select 
            label="label" 
            :options="[
              { label: 'All' },
              { label: 'Short/Lacking' },
              { label: 'Damage' },
              { label: 'Thawed/Melted' },
              { label: 'Invoice Related' },
              { label: 'No Show' },
              { label: 'Others' }
            ]"
            :reduce="item => item.label"
            v-model="charge_type"
            placeholder="Charge Type"
          > 
          </v-select>  
        </CCol>
         <CCol lg="3">
           <v-select 
            label="label" 
            :options="[
              {
                label: 'All',
                value: 'all'
              },
               {
                label: 'Pending',
                value: 'Pending'
              },
              {
                label: 'Recovered',
                value: 'Recovered'
              },
              {
                label: 'Paid',
                value: 'Paid'
              }
              
            ]"
            :reduce="item => item.value"
            v-model="status"
            placeholder="Status"
          > </v-select>
        </CCol> 
        
        <!-- <CCol lg="2">
          
        </CCol> -->
        <!-- <CCol lg="2" class="float-right">
          <CButton size="sm" block color="info" @click="search"><i class="icon-magnifier"></i> Search</CButton>
        </CCol> -->
      </CRow>
       
      <CRow>
        <CCol lg="3">
          <Datepicker v-model="created_date_from" input-class="form-control" placeholder="Date Created from" />
          
        </CCol>
        <CCol lg="3">
          <Datepicker v-model="created_date_to" input-class="form-control" placeholder="Date Created to" />
        </CCol>
         <CCol lg="3">
           <v-select 
            label="label" 
            :options="[
              {
                label: 'All',
                value: 'all'
              },
               {
                label: 'Subcon',
                value: 'Subcon'
              },
              {
                label: 'Not-chargeable',
                value: 'Not-chargeable'
              },
              {
                label: 'Personnel-helper/s',
                value: 'Not-chargeable'
              },
               {
                label: 'Personnel-driver',
                value: 'Not-chargeable'
              },
              {
                label: 'Personnel-all',
                value: 'Personnel-all'
              }
            ]"
            :reduce="item => item.value"
            v-model="charge_to"
            placeholder="Charged to"
          > </v-select>
        </CCol> 
        <CCol lg="3">
          <CInput size="sm" v-model="charge_ref_no" placeholder="Charge Ref No."/>
        </CCol>
       
        <!-- <CCol lg="2">
          
        </CCol> -->
        <!-- <CCol lg="2" class="float-right">
          <CButton size="sm" block @click="clearSearch" color="info">Clear Search</CButton>
        </CCol> -->
      </CRow>
      <CRow class="mt-2">
        <CCol lg="3">
          <Datepicker v-model="delivery_date_from" input-class="form-control" placeholder="Delivery date from" />
          
        </CCol>
        <CCol lg="3">
          <Datepicker v-model="delivery_date_to" input-class="form-control" placeholder="Delivery date to" />
        </CCol>

        <CCol lg="3">
          <v-select 
            :disabled="depotid"
            label="setting_name" 
            :options="depotList.data"
            :reduce="item => item.id"
            v-model="depot_id"
            placeholder="Depot"
          > 
          </v-select> 
        </CCol>
        <CCol lg="3" class="float-right mt-1">
          <CButton size="sm" color="info" variant="outline" style="margin-right: 10px"  @click="search">Search</CButton>
       
          <CButton size="sm" color="info" variant="outline" @click="clearSearch" style="margin-right: 10px"  >Clear Filter</CButton>
         
        </CCol>
      </CRow>
  </div>
</template>

<script>
  import config from '../config.js';
  import axios from '../axios';
   import moment from 'moment';
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css';
   import Datepicker from 'vuejs-datepicker';
    export default {
        name: "BookingTypeSearch",
        data() {
          return {
            booking_no: "",
            customer_id: "",
            charge_type: "",
            status: "",
            created_date_from: "",
            created_date_to: "",
            charge_to: "",
            charge_ref_no: "",
            depot_id : "",
            depotid : null,
            customerList: {
              data: []
            },  
             depotList : {
              data : []
            },
            delivery_date_to: new Date(),
            delivery_date_from: moment(new Date()).subtract(7, 'days')._d, 
          }
        },
      mounted() {
       
        this.getCustomer();
        this.getDepot();

        this.$nextTick(() => {
          this.depot_id = config?.global_user_data?.depot_id ?? "";
          this.depotid = this.depot_id === "" ? null : this.depot_id;
        })
        
        
      },
      components: {vSelect,Datepicker},
      methods: {
      //    getDepot(){

      //     axios.get(config.api_path+'/setting?setting_type=depot&page=1&limit=10000000')
      //       .then(response => {
      //         this.depotList = response.data;
      //         this.depotList.data = this.depotList.data.map((value, index)=>{
      //         value.value = value.id;
      //         value.label = value.setting_name;
      //         return value;
      //       })
      //     })

	    // }, 
      getDepot(){

          axios.get(config.api_path+'/reference/settings-list',{
              params:{
                setting_type:'depot'
              }
                })
          .then(response => {
            this.depotList = response.data; 
          })

          },
          
        search() {
          const data = {
            booking_no: this.booking_no,
            customer_id: this.customer_id,
            charge_type: this.charge_type,
            status: this.status,
            created_date_from: this.created_date_from,
            created_date_to: this.created_date_to,
            charge_to: this.charge_to,
            charge_ref_no: this.charge_ref_no,
            delivery_date_from : this.delivery_date_from,
            delivery_date_to : this.delivery_date_to,
            depot_id : this.depot_id
          }
          this.$emit("depot-search-query", data)
        },
        clearSearch() {
          this.booking_no = "";
          this.customer_id = "";
          this.charge_type ="";
          this.status="";
          this.created_date_from="";
          this.created_date_to="";
          this.charge_to="";
          this.charge_ref_no = "";
          this.depot_id = this.depotid;
          this.delivery_date_to = new Date();
          this.delivery_date_from = moment(new Date()).subtract(7, 'days')._d;
   
          const data = {
            booking_no: this.booking_no,
            customer_id: this.customer_id,
            charge_type: this.charge_type,
            status: this.status,
            created_date_from: this.created_date_from,
            created_date_to: this.created_date_to,
            charge_to: this.charge_to,
            charge_ref_no: this.charge_ref_no,
            delivery_date_from : this.delivery_date_from,
            delivery_date_to : this.delivery_date_to,
            depot_id : this.depot_id
          }
          this.$emit("depot-search-query", data)
        },
        getCustomer(){
	    	axios.get(config.api_path+'/reference/customer-list', {
            params: {
              show_all : true
            }
          })
	    	.then(response => {
	    		this.customerList = response.data; 
	    	})

	    }, 

      //   getCustomer(){

      //   axios.get(config.api_path+'/customer?customer_name&page=1&limit=10000000')
      //   .then(response => {
      //     this.customerList = response.data; 
      //     this.customer.data.unshift({
      //       customer_name: 'All',
      //       id: 'all'
      //     })
      //   })

      // },
   

      
      }
    }
</script>

<style scoped>

</style>
