<template>
    <CModal title="Download Report" :show.sync="showModalDownloadFilter_" color="info" size="md" @close="() => { showModalDownloadFilter_ = false }">
        <form @submit.prevent="approve">
			<div class="pl-3 pr-3 pb-3">
                <h5>Choose a filter to download a report</h5>
                <br>
				<CRow class="mb-2">
                    <CCol lg="6" class="mb-2 label-font-10">
						<label class="mb-1">Delivery Date From</label>
                        <Datepicker v-model="filter.delivery_date_from" input-class="form-control" placeholder="Delivery Date from" />
					</CCol>
                    <CCol lg="6" class="mb-2 label-font-10">
                        <label class="mb-1">Delivery Date To</label>
                        <Datepicker v-model="filter.delivery_date_to" input-class="form-control" placeholder="Delivery Date to" />
                    </CCol>
                    
                    <CCol lg="6" class="mb-2 label-font-10">
						<label class="mb-1">Created Date From</label>
                        <Datepicker v-model="filter.created_date_from" input-class="form-control" placeholder="Created Date from" />
					</CCol>
                    <CCol lg="6" class="mb-2 label-font-10">
                        <label class="mb-1">Created Date To</label>
                        <Datepicker v-model="filter.created_date_to" input-class="form-control" placeholder="Created Date to" />
                    </CCol>
                    <CCol lg="12" class="mb-0 label-font-10">
                        <label class="mb-1">Booking No.</label>
                        <CInput v-model="filter.booking_no" />
                    </CCol>
                    <CCol lg="12" class="mb-2 label-font-10">
						<label class="mb-1">Customer</label>
                        <v-select label="customer_name" :options="customer_list.data" :reduce="item => item.id" v-model="filter.customer_id" placeholder="-Select-"></v-select>
					</CCol>
                    <CCol lg="12" class="mb-2 label-font-10">
						<label class="mb-1">Depot</label>
						<v-select label="setting_name" :options="depot_list.data" :reduce="item => item.id" v-model="filter.depot_id" placeholder="-Select-"></v-select>
					</CCol>
                    <CCol lg="12" class="mb-2 label-font-10">
						<label class="mb-1">Charge Type</label>
						<v-select label="label" :options="charge_type_list" :reduce="item => item.label" v-model="filter.charge_type" placeholder="-Select-"></v-select>
					</CCol>

                    <CCol lg="12" class="mb-0 label-font-10">
                        <label class="mb-1">Charged To</label>
                        <v-select 
                            label="label" 
                            :options="[
                            {
                                label: 'All',
                                value: 'all'
                            },
                            {
                                label: 'Subcon',
                                value: 'Subcon'
                            },
                            {
                                label: 'Not-chargeable',
                                value: 'Not-chargeable'
                            },
                            {
                                label: 'Personnel-helper/s',
                                value: 'Not-chargeable'
                            },
                            {
                                label: 'Personnel-driver',
                                value: 'Not-chargeable'
                            },
                            {
                                label: 'Personnel-all',
                                value: 'Personnel-all'
                            }
                            ]"
                            :reduce="item => item.value"
                            v-model="filter.charge_to"
                           
                        > </v-select>
                    </CCol> 

                    <CCol lg="12" class="mb-2 label-font-10">
                        <label class="mb-1">Charge Ref No.</label>
                        <CInput v-model="filter.charge_ref_no" />
                    </CCol>
                
                    <CCol lg="12" class="mb-2 label-font-10">
						<label class="mb-1">Status</label>
						<v-select label="label" :options="status_list" :reduce="item => item.label" v-model="filter.status" placeholder="-Select-"></v-select>
					</CCol>
                    <CCol lg="12" class="mb-2 label-font-10">
                        <h6 class="text-danger"> {{error_msg}}</h6>
                    </CCol>
				</CRow>
            </div>
        </form>
        <div slot="footer" class="w-100">
            <!-- <CButton class="ml-1 mr-1 float-right" size="sm" color="info" @click="download"><i class="fa fa-file"></i> Generate Report</CButton>
            <CButton class="ml-1 mr-1 float-right" size="sm" color="light" @click="clear">Clear</CButton> -->
            
            <CButton class="float-right" size="sm" color="info" @click="download" v-if="!processing"><i class="fa fa-file"></i> Generate Report</CButton>
            <a :href="link_download" v-if="ready_download"><i class="fa fa-file"></i> Download Report</a>
            <CButton color="primary" disabled v-if="processing && !ready_download">
                <CSpinner component="span" size="sm" variant="grow" aria-hidden="true"/>
                Generating Report. Please wait ...
            </CButton>
            <CButton class="ml-1 mr-1 float-right" size="sm" color="light" @click="clear" v-if="!processing"><i class="fa fa-close"></i> Clear</CButton>
        </div>
    </CModal>
</template>

<script>
import config from '../config.js';
import axios from '../axios';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import moment, { months } from 'moment';  
import Datepicker from 'vuejs-datepicker';
import 'vue-select/dist/vue-select.css';
export default {
    components: { vSelect, Datepicker },
    mounted() {
        
    },
    props: {
        showModalDownloadFilter: {required: true},
        booking_no: {required: true},
        filters : {}
    },
    data() {
        return {
            showModalDownloadFilter_:false,
            filter: {
                booking_no:'',
				customer_id: '',
				depot_id: '',
                charge_type: '',
                status: '',
				delivery_date_to: new Date(),
				delivery_date_from:  moment(new Date()).subtract(7, 'days')._d, 
                created_date_from: null,
				created_date_to: null,
                charge_to: '',
                charge_ref_no: ''
			},
            depot_list:[],
            customer_list:[],
            charge_type_list:[
                // { label: 'All' },
                { label: 'Short/Lacking' },
                { label: 'Damage' },
                { label: 'Thawed/Melted' },
                { label: 'Invoice Related' },
                { label: 'No Show' },
                { label: 'Others' }
            ],
            status_list:[
                // { label: 'All' }, 
                { label: 'Pending' },
                { label: 'Recovered' },
                { label: 'Paid' },
            ],
            currentPage:1,
            error_msg: '',
            processing:false,
            ready_download:false,
            link_download:'',
            report_downloaded: false
        }
    },
    methods: {
        async download() {
            this.report_downloaded = false;
            if(this.dataValidate() && this.dateRangeValidate(this.filter.delivery_date_from, this.filter.delivery_date_to) && this.dateFilterValidatePrompt(this.filter.created_date_from, this.filter.created_date_to)){
                var delivery_date_from = this.filter.delivery_date_from;
                if(delivery_date_from != "" ){
                    delivery_date_from = moment(delivery_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
                }
                else{
                    delivery_date_from = ''
                }
                var delivery_date_to = this.filter.delivery_date_to;
                if(delivery_date_to != ""){	
                    delivery_date_to = moment(delivery_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
                    // delivery_date_to = delivery_date_to + 86399; 
                }else{
                    delivery_date_to = ''
                }
                var created_date_from = this.filter.created_date_from;
                if(created_date_from){
                    created_date_from = moment(created_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
                    created_date_from = moment.unix(created_date_from).format('YYYY-MM-DD HH:mm:ss')
                }
                var created_date_to = this.filter.created_date_to;
                if(created_date_to){
                    created_date_to = moment(created_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
                    created_date_to = moment.unix(created_date_to).format('YYYY-MM-DD HH:mm:ss')
                }
                this.ready_download = false;
                this.processing = true;
                this.no_filter = false;
                // var created_date_from = this.filter.created_date_from;
                // if(created_date_from != "" && created_date_from != "NaN"){	
                //     created_date_from = moment(created_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
                //     created_date_from = created_date_from + 86399; 
                // }else{
                //     created_date_from = null
                // }
                // var created_date_to = this.filter.created_date_to;
                // if(created_date_to != "" && created_date_to != "NaN"){	
                //     created_date_to = moment(created_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
                //     created_date_to = created_date_to + 86399; 
                // }else{
                //     created_date_to = null
                // }
                // this.$showLoading(true)
                // await axios.get(`${config.api_path}/reports/booking_charges`, {
                //     params: {
                //         company_id:config.global_user_data.company_id,
                //         customer_id:this.filter.customer_id ?? "",
                //         depot_id:this.filter.depot_id ?? "",
                //         status:this.filter.status ?? "",
                //         charge_type:this.filter.charge_type ?? "",
                //         delivery_date_from:delivery_date_from ?? "",
                //         delivery_date_to:delivery_date_to ?? "",
                //         booking_no:this.booking_no ?? ""
                //     }
                // }
           
               
            // })
            await axios.post(`${config.api_path}/reports/booking_charges`, {
                company_id:config.global_user_data.company_id,
                customer_id:this.filter.customer_id,
                depot_id:this.filter.depot_id,
                status:this.filter.status,
                charge_type:this.filter.charge_type,
                booking_no:this.filter.booking_no,
                delivery_date_from,
                delivery_date_to,
                created_date_from,
                created_date_to,
                charge_to : this.filter.charge_to,
                charge_ref_no : this.filter.charge_ref_no
                
            }
                // '&created_date_from='+ created_date_from+
                // '&created_date_to='+ created_date_to 
                ).then(response=>{
                    this.link_download = config.main_path.replace('/index.php', '')+'/'+response.data.file
                    this.watchDownload(response.data.file);
                    this.error_msg = '';
                    // setTimeout(() => {
                    //     this.$showLoading(false)
                    //     window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file)
                      
                    // }, 3000);
                    // setTimeout(() => {
                    //     axios.get(`${config.api_path}/remove-file`,{
                    //         params : {
                    //             filename : response.data.file
                    //         }
                    //     })	
                    // }, 5000);
                })
                .catch(err => {
                    // this.$showLoading(false)
                    this.no_filter = true;
                    this.ready_download = false;
                    this.processing = false;
                });
            }
            else{
                this.no_filter = true;
                this.ready_download = false;
                this.processing = false;
                this.error_msg = 'Please select a filter before downloading the report.';
                this.dateFilterValidatePrompt(this.filter.created_date_from, this.filter.created_date_to, 'created');
                this.dateFilterValidatePrompt(this.filter.delivery_date_from, this.filter.delivery_date_to, 'delivery');
            }
        },
        getCustomer() {
            axios.get(config.api_path + '/reference/customer-list', {
                params: {show_all: true}
            }).then(response => {
                this.customer_list = response.data;
            });
        },
        getDepot(){
            axios.get(config.api_path+'/reference/settings-list', {
                params:{ setting_type:'depot'}
             }) .then(response => {
                this.depot_list = response.data;
            });
        },
        clear(){
            this.filter.booking_no = '';
            this.filter.customer_id = '';
            this.filter.depot_id = '';
            this.filter.charge_type = '';
            this.filter.status = '';
            this.filter.delivery_date_to = new Date();
            this.filter.delivery_date_from = moment(new Date()).subtract(7, 'days')._d;
            this.filter.created_date_from = null;
            this.filter.created_date_to = null;
            this.filter.charge_to = '';
            this.filter.charge_ref_no = '';
        },
        dataValidate(){
            return true;
            if(
                this.filter.booking_no ||
                this.filter.customer_id || 
                this.filter.depot_id || 
                this.filter.charge_type ||
                this.filter.status ||
                (this.filter.delivery_date_from && this.filter.delivery_date_to && this.dateRangeValidate(this.filter.delivery_date_from, this.filter.delivery_date_to)) ||
                (this.filter.created_date_from && this.filter.created_date_to && this.dateRangeValidate(this.filter.created_date_from, this.filter.created_date_to))
            ){
                return true;
            }
            else{
                return false;
            }
        },
        dateRangeValidate(f, t){
            let from = f;
            let to = t;
            if(!from&&!to){
                return true;
            }else{
                if(from && to){
                    const months = moment(t).diff(f, 'months');
                    console.log('MONEHTS', months)

                    const start = new Date(from);
                    const end = new Date(to);
                    const differenceInMilliseconds = end - start;
                    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24 * 30);
                    return months <= 12;
                }
                else{
                    return false;
                }
            }
        },
        dateFilterValidatePrompt(f, t, type = '1'){
            let from = f;
            let to = t;
            
            if(from && to){

                const months = moment(t).diff(f, 'months');
            console.log('MONEHTSssss', months)

                const start = new Date(from);
                const end = new Date(to);
                const differenceInMilliseconds = end - start;
                const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24 * 30);
                if(!(months <= 12)){
                    if(type == 'delivery'){
                        this.error_msg = 'Filter date for delivery date should be maximum of 12 months.';
                    }
                    else if(type == 'created'){
                        this.error_msg = 'Filter date for created date should be maximum of 12 months.';
                    }
                    return false;
                }
                else{
                    return true;
                }
            }
            else{
                return true
            }
        },
        watchDownload(file){
            const self = this;
            self.interval_import_program = setInterval(function () {
                axios.get(`${config.api_path}/watch-download`,{
                    params : {
                        filename : file
                    }
                }).then(response => {
                    if(response.data.status){
                        self.ready_download = true;
                        self.processing = false;
                        clearInterval(self.interval_import_program);
                        if(!self.report_downloaded) {
                            self.report_downloaded = true;
                            window.open(config.main_path.replace('/index.php', '')+'/'+file)
                        }
                    }
                });
            }, 1000);
        }
    },
    watch: {
        showModalDownloadFilter_: function(val){
            this.$emit('closeModalDownloadFilter', val)
        },
        showModalDownloadFilter: async function(){
            this.showModalDownloadFilter_ = this.showModalDownloadFilter;

            if(this.processing || this.ready_download)
                return;

            if(!this.customer_list?.data?.length)
                await this.getCustomer();
            if(!this.depot_list?.data?.length)
                await this.getDepot();

            this.download()
        },
        filters: {
            deep: true,
            handler() {
                this.filter = {...this.filters}
                this.ready_download = false;
            }
        }
    },
    
    
}
</script>

<style>
.modal-header {
    background-color: white !important;
    
}
.modal-title {
    color: black;
}
</style>
