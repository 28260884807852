<template>
	<div>
		<BlockUI :message="defaultMsg" :html="html" v-if="isDownloading"></BlockUI>
		
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="6">
					<h5>Booking Charges Report ({{ dataList.total }})</h5> 
			</CCol>
	     		 <CCol lg="6">
		      		 <CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info"><i class="fa fa-search"></i>Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
			        <!-- <CButton 
							v-if="config.getPermission('booking_charges_reports').download"
							size="sm" style="margin-right: 10px" 
							color="btn btn-outline-primary"
							@click="download"
							class="float-lg-right">
							<i class="fa fa-download">
							</i> Download
					</CButton> -->
					<CButton 
						v-if="config.getPermission('booking_charges_reports').download"
						size="sm" style="margin-right: 10px" 
						color="btn btn-outline-primary"
						@click="() => { showModalDownloadFilter = true }"
						class="float-lg-right">
						<i class="fa fa-download">
						</i> Download
					</CButton>
			       
			    </CCol>
		</CRow>
		<CRow> 
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
				        <CCard class="p-4">
				          <Search @depot-search-query="search"/>
				        </CCard>
				      </CCollapse> 
				</CCol>
		</CRow> 
		<hr>

		<CRow>			
	      	 
		      <CCol lg="12">  
				<div class="table-responsive-sm table-responsive-md table-responsive-lg">
	      		<CDataTable
					:loading="isLoading"
			      	:items="dataList.data"
			      	:fields="fields"
					hover
					striped
					outlined
					
					
			    >  
				<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:capitalize;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
					      	</template>

			    </CDataTable> 
			    <CPagination
		          	:active-page.sync="currentPage"
		          	:pages="Math.ceil(dataList.total / 20)"
		          	:activePage="currentPage"
		          	@update:activePage="updatePage"
		        /> 
			</div>
	      	</CCol>
	    </CRow> 
		 
		</CCard>
		<download-filter :filters="filter" :showModalDownloadFilter="showModalDownloadFilter" :booking_no="filter.booking_no" @closeModalDownloadFilter="closeModalDownloadFilter"/>
	</div>
</template>
<script> 
import config from '../config.js';
import axios from '../axios';
import Swal from 'sweetalert2';
import Search from './booking_charges_search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'; 
import moment from 'moment';
import Loader from '../../loader.mixin';
import '../style.css';
import downloadFilter from './booking_charges_filter_report.vue';

export default {
	mixins: [Loader],
	mounted(){  
		// this.getVendor();
		
		this.$nextTick(() => {
			this.filter.depot_id = config?.global_user_data?.depot_id ?? "";
			this.getData();  
		})

	},
	data(){
		return{
			isDownloading : false,
			config,
			isLoading: false,
			dataList: [],  

			fields: [
				{
					key: 'booking_no', 
					label: 'Booking No.'
				},
				{
					key: 'delivery_date', 
					label: 'Delivery Date'
				}, 
				{
					key: 'customer_name', 
					label: 'Customer'
				}, 
				{
					key: 'depot', 
					label: 'Depot'
				}, 
				{
					key: 'commodity_id_label', 
					label: 'Commodity'
				},
				{
					key: 'created_at_label', 
					label: 'Date Created'
				},
				
				{
					key: 'charge_ref_no', 
					label: 'Charge Ref No.'
				},

				
				
				{
					key: 'charge_type', 
					label: 'Charge Type'
				},
				
	
				{
					key: 'charge_to', 
					label: 'Charged To'
				},
	
				{
					key: 'total_charge', 
					label: 'Amount'
				},
				{
					key: 'status', 
					label: 'Status'
				},
				
			
			],
			currentPage: 1,  


			editMode: false,
			selectedRow: {},
			showCollapse: false,
			filter: {
				booking_no: "",
	            customer_id: "",
	            charge_type: "",
	            status: "",
	            created_date_from: "",
	            created_date_to: "",
	            charge_to: "",
	            charge_ref_no: "",
				depot_id : "",
				delivery_date_to: new Date(),
            	delivery_date_from: moment(new Date()).subtract(7, 'days')._d, 
			},
			// vendorList: {
			// 	data: []
			// },
			showModalDownloadFilter:false,
		}
	},
	name: 'Tables',
	components: { Search, Datepicker, vSelect, downloadFilter},
	methods: {
		getBadge (status) {
			return status === 'Paid' ? 'success'
				: status === 'Recovered' ? 'primary'
				: status === 'Pending' ? 'danger' : 'info'
		},
		reloadData(){
			this.getData();  
		},
		updatePage(data){
			this.currentPage = data;
			this.getData();
		},   

	
	    search(event){
	    	this.filter = event; 
			console.log(event)
	    	this.getData();  
	    }, 
		
	    getData(){  

	     this.isLoading=true; 
		 
	    	var customer_id = this.filter.customer_id;
	    	if(customer_id == "All" || customer_id == "all" || customer_id == null){
	    		customer_id = ""
	    	} 
	    	var charge_type = this.filter.charge_type;
	    	if(charge_type == "All" || charge_type == "all" || charge_type == null){
	    		charge_type = ""
	    	}
			var status = this.filter.status;
	    	if(status == "All" || status == "all" || status == null){
	    		status = ""
	    	}
	    	var charge_to = this.filter.charge_to;
	    	if(charge_to == "All" || charge_to == "all" || charge_to == null){
	    		charge_to = ""
	    	} 
	    	var created_date_from = this.filter.created_date_from;
	    	if(created_date_from != ""){
	    		created_date_from = moment(created_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    		created_date_from = moment.unix(created_date_from).format('YYYY-MM-DD HH:mm:ss')
	    	}
	    	var created_date_to = this.filter.created_date_to;
	    	if(created_date_to != ""){
	    		created_date_to = moment(created_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    		created_date_to = moment.unix(created_date_to).format('YYYY-MM-DD HH:mm:ss')
	    	}
			
			let delivery_date_to = this.filter.delivery_date_to;
	    	if(delivery_date_to != ""){
	    		delivery_date_to = moment(delivery_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    		// delivery_date_to = moment.unix(delivery_date_to).format('YYYY-MM-DD HH:mm:ss')
	    	}
			let delivery_date_from = this.filter.delivery_date_from;
	    	if(delivery_date_from != ""){
	    		delivery_date_from = moment(delivery_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    		// delivery_date_from = moment.unix(delivery_date_from).format('YYYY-MM-DD HH:mm:ss')
	    	}
			
			axios.get(`${config.api_path}/booking_charges`, {
				params : {
					booking_no : this.filter.booking_no,
					customer_id,
					charge_type,
					status,
					created_date_from,
					created_date_to,
					delivery_date_to,
					delivery_date_from,
					charge_to,
					charge_ref_no : this.filter.charge_ref_no,
					page: this.currentPage,
					depot_id : this.filter.depot_id,
					limit : 20
				}
			})
	    	// axios.get(config.api_path+'/booking_charges?booking_no='+
			// 	this.filter.booking_no+
			// 	'&customer_id='+customer_id+"&charge_type="+charge_type+'&status='+status+'&created_date_from='+created_date_from+"&created_date_to="+created_date_to+"&charge_to="+charge_to+"&charge_ref_no="+this.filter.charge_ref_no+'&page='+this.currentPage+'&limit=20')
	    	.then(response => {
	    		this.dataList = response.data; 
	    		this.dataList.data = this.dataList.data.map((value, index)=>{ 

	    			value.booking_no = value.booking.booking_no;
	    			value.customer_name = value.booking.customer.customer_name;
	    			value.created_at_label = moment.utc(value.created_at, "YYYY-MM-DD").format('MM/DD/YYYY');
	    			value.delivery_date = value.booking.delivery_date ?  moment.unix(value.booking.delivery_date).format('MM/DD/YYYY') : '';
	    			value.commodity_id_label = value.booking.commodity.setting_name
					value.charge_ref_no = value.charge_ref_no != "" ? value.charge_ref_no : "N/A";

					value.depot = value.booking?.depot?.setting_name ?? "";
	    			
 					
	    			return value;
	    		}); 
	    	})
				.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    },

	    
	   

	    // getVendor(){

	    // 	axios.get(config.api_path+'/vendor?page=1&limit=10000000')
	    // 	.then(response => {
	    // 		this.vendorList = response.data;
	    // 		this.vendorList.data = this.vendorList.data.map((value, index)=>{
	    // 			value.value = value.id;
	    // 			value.label = value.vendor_name;
	    // 			return value;
	    // 		})
	    // 	})

	    // },

	   

	    download(){ 
			this.isDownloading = true;
	    	var customer_id = this.filter.customer_id;
	    	if(customer_id == "All" || customer_id == "all" || customer_id == null){
	    		customer_id = ""
	    	} 
	    	var charge_type = this.filter.charge_type;
	    	if(charge_type == "All" || charge_type == "all" || charge_type == null){
	    		charge_type = ""
	    	}
			var status = this.filter.status;
	    	if(status == "All" || status == "all" || status == null){
	    		status = ""
	    	}
	    	var charge_to = this.filter.charge_to;
	    	if(charge_to == "All" || charge_to == "all" || charge_to == null){
	    		charge_to = ""
	    	} 
	    	var created_date_from = this.filter.created_date_from;
	    	if(created_date_from != ""){
	    		created_date_from = moment(created_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    		created_date_from = moment.unix(created_date_from).format('YYYY-MM-DD HH:mm:ss')
	    	}
	    	var created_date_to = this.filter.created_date_to;
	    	if(created_date_to != ""){
	    		created_date_to = moment(created_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    		created_date_to = moment.unix(created_date_to).format('YYYY-MM-DD HH:mm:ss')
	    	}
				
			let delivery_date_to = this.filter.delivery_date_to;
	    	if(delivery_date_to != ""){
	    		delivery_date_to = moment(delivery_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    		// delivery_date_to = moment.unix(delivery_date_to).format('YYYY-MM-DD HH:mm:ss')
	    	}
			let delivery_date_from = this.filter.delivery_date_from;
	    	if(delivery_date_from != ""){
	    		delivery_date_from = moment(delivery_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    		// delivery_date_from = moment.unix(delivery_date_from).format('YYYY-MM-DD HH:mm:ss')
	    	}
			axios.get(config.api_path+'/reports/booking_charges',{
				params : {
					booking_no : this.filter.booking_no,
					customer_id,
					charge_type,
					status,
					created_date_from,
					created_date_to,
					delivery_date_to,
					delivery_date_from,
					charge_to,
					charge_ref_no : this.filter.charge_ref_no,
					depot_id : this.filter.depot_id
				}
			}).then(response=>{
				this.isDownloading = false;
	    		window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 

				setTimeout(() => {
					axios.get(`${config.api_path}/remove-file`,{
						params : {
							filename : response.data.file
						}
					})	
				}, 2000);
				
	    	}).catch(err => {
				this.isDownloading = false;
			})
			
	    	// axios.get(config.api_path+'/reports/booking_charges?booking_no='+this.filter.booking_no+
			// '&customer_id='+customer_id+
			// "&charge_type="+charge_type+
			// '&status='+status+
			// '&created_date_from='+created_date_from+
			// "&created_date_to="+created_date_to+
			// "&depot_id="+this.filter.depot_id+
			// "&charge_to="+charge_to+
			// "&charge_ref_no="+this.filter.charge_ref_no)
	    	
	    },
		closeModalDownloadFilter(status){
			this.showModalDownloadFilter = status;
		}, 
 	}
}
</script>
