var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-4"},[_c('CRow',{},[_c('CCol',{attrs:{"lg":"3"}},[_c('CInput',{attrs:{"placeholder":"Booking No."},model:{value:(_vm.booking_no),callback:function ($$v) {_vm.booking_no=$$v},expression:"booking_no"}})],1),_c('CCol',{attrs:{"lg":"3"}},[_c('v-select',{attrs:{"label":"customer_name","options":_vm.customerList.data,"reduce":function (item) { return item.id; },"placeholder":"Customer"},model:{value:(_vm.customer_id),callback:function ($$v) {_vm.customer_id=$$v},expression:"customer_id"}})],1),_c('CCol',{attrs:{"lg":"3"}},[_c('v-select',{attrs:{"label":"label","options":[
            { label: 'All' },
            { label: 'Short/Lacking' },
            { label: 'Damage' },
            { label: 'Thawed/Melted' },
            { label: 'Invoice Related' },
            { label: 'No Show' },
            { label: 'Others' }
          ],"reduce":function (item) { return item.label; },"placeholder":"Charge Type"},model:{value:(_vm.charge_type),callback:function ($$v) {_vm.charge_type=$$v},expression:"charge_type"}})],1),_c('CCol',{attrs:{"lg":"3"}},[_c('v-select',{attrs:{"label":"label","options":[
            {
              label: 'All',
              value: 'all'
            },
             {
              label: 'Pending',
              value: 'Pending'
            },
            {
              label: 'Recovered',
              value: 'Recovered'
            },
            {
              label: 'Paid',
              value: 'Paid'
            }
            
          ],"reduce":function (item) { return item.value; },"placeholder":"Status"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1),_c('CRow',[_c('CCol',{attrs:{"lg":"3"}},[_c('Datepicker',{attrs:{"input-class":"form-control","placeholder":"Date Created from"},model:{value:(_vm.created_date_from),callback:function ($$v) {_vm.created_date_from=$$v},expression:"created_date_from"}})],1),_c('CCol',{attrs:{"lg":"3"}},[_c('Datepicker',{attrs:{"input-class":"form-control","placeholder":"Date Created to"},model:{value:(_vm.created_date_to),callback:function ($$v) {_vm.created_date_to=$$v},expression:"created_date_to"}})],1),_c('CCol',{attrs:{"lg":"3"}},[_c('v-select',{attrs:{"label":"label","options":[
            {
              label: 'All',
              value: 'all'
            },
             {
              label: 'Subcon',
              value: 'Subcon'
            },
            {
              label: 'Not-chargeable',
              value: 'Not-chargeable'
            },
            {
              label: 'Personnel-helper/s',
              value: 'Not-chargeable'
            },
             {
              label: 'Personnel-driver',
              value: 'Not-chargeable'
            },
            {
              label: 'Personnel-all',
              value: 'Personnel-all'
            }
          ],"reduce":function (item) { return item.value; },"placeholder":"Charged to"},model:{value:(_vm.charge_to),callback:function ($$v) {_vm.charge_to=$$v},expression:"charge_to"}})],1),_c('CCol',{attrs:{"lg":"3"}},[_c('CInput',{attrs:{"size":"sm","placeholder":"Charge Ref No."},model:{value:(_vm.charge_ref_no),callback:function ($$v) {_vm.charge_ref_no=$$v},expression:"charge_ref_no"}})],1)],1),_c('CRow',{staticClass:"mt-2"},[_c('CCol',{attrs:{"lg":"3"}},[_c('Datepicker',{attrs:{"input-class":"form-control","placeholder":"Delivery date from"},model:{value:(_vm.delivery_date_from),callback:function ($$v) {_vm.delivery_date_from=$$v},expression:"delivery_date_from"}})],1),_c('CCol',{attrs:{"lg":"3"}},[_c('Datepicker',{attrs:{"input-class":"form-control","placeholder":"Delivery date to"},model:{value:(_vm.delivery_date_to),callback:function ($$v) {_vm.delivery_date_to=$$v},expression:"delivery_date_to"}})],1),_c('CCol',{attrs:{"lg":"3"}},[_c('v-select',{attrs:{"disabled":_vm.depotid,"label":"setting_name","options":_vm.depotList.data,"reduce":function (item) { return item.id; },"placeholder":"Depot"},model:{value:(_vm.depot_id),callback:function ($$v) {_vm.depot_id=$$v},expression:"depot_id"}})],1),_c('CCol',{staticClass:"float-right mt-1",attrs:{"lg":"3"}},[_c('CButton',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm","color":"info","variant":"outline"},on:{"click":_vm.search}},[_vm._v("Search")]),_c('CButton',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm","color":"info","variant":"outline"},on:{"click":_vm.clearSearch}},[_vm._v("Clear Filter")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }